import React, { ComponentProps, useState } from 'react'

import { Select } from '@te-digi/styleguide'
type Value = ComponentProps<typeof Select>['value']

const SelectIsLoadingExample = () => {
  const [value, setValue] = useState<Value>(null)
  return (
    <Select
      label="Kaupunki"
      options={[]}
      isLoading
      value={value}
      onChange={val => setValue(val)}
    />
  )
}

export default SelectIsLoadingExample
