import React, { ComponentProps, useState } from 'react'

import { FormGroup, FormText, Select } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

type Value = ComponentProps<typeof Select>['value']

const SELECT_OPTIONS_WITH_GROUPS = [
  {
    label: 'Helsinki',
    value: 'helsinki',
    group: { label: 'Kaupunki', value: 'city' }
  },
  { label: 'Suomi', value: 'suomi', group: { label: 'Maa', value: 'country' } },
  {
    label: 'Espoo',
    value: 'espoo',
    group: { label: 'Kaupunki', value: 'city' }
  },
  {
    label: 'Ruotsi',
    value: 'ruotsi',
    group: { label: 'Maa', value: 'country' }
  },
  {
    label: 'Tampere',
    value: 'tampere',
    group: { label: 'Kaupunki', value: 'city' }
  },
  { label: 'Norja', value: 'norja', group: { label: 'Maa', value: 'country' } },
  {
    label: 'Seinäjoki',
    value: 'seinajoki',
    group: { label: 'Kaupunki', value: 'city' }
  },
  {
    label: 'Tanska',
    value: 'tanska',
    group: { label: 'Maa', value: 'country' }
  }
]

const SelectGroupAdvancedExample = () => {
  const [option, setOption] = useState<Value>(SELECT_OPTIONS_WITH_GROUPS[0])

  return (
    <FormGroup noMargin>
      <Select
        filter="start"
        label="Kaupunki"
        onChange={value => setOption(value)}
        options={SELECT_OPTIONS_WITH_GROUPS}
        searchable
        value={option}
      />
      <FormText>
        Valittu: {option ? <Code>{JSON.stringify(option)}</Code> : '-'}
      </FormText>
    </FormGroup>
  )
}

export { SelectGroupAdvancedExample }
