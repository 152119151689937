import React, { useState } from 'react'
import { FormGroup, Select } from '@te-digi/styleguide'
const SELECT_OPTIONS = [{ label: 'Helsinki', value: 'helsinki-1' }]

const SelectDisabledExample = () => {
  const [option] = useState(SELECT_OPTIONS[0])
  return (
    <FormGroup noMargin>
      <Select
        disabled
        label="Kaupunki"
        options={SELECT_OPTIONS}
        value={option}
      />
    </FormGroup>
  )
}

export { SelectDisabledExample }
