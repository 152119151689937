import React, { ElementType } from 'react'

interface LabelHTMLExampleProps {
  as?: ElementType
  children?: string
  className?: string
  disabled?: boolean
  htmlFor?: string
  id?: string
  required?: boolean
  subLabel?: string
  subLabelId?: string
}

const LabelHTMLExample = ({
  as: Component = 'label',
  children,
  className,
  disabled,
  htmlFor,
  id,
  required,
  subLabel,
  subLabelId
}: LabelHTMLExampleProps) => (
  <div
    className={['label', disabled ? 'disabled' : '', className ? className : '']
      .filter(Boolean)
      .join(' ')}
  >
    <div className="label-headings">
      <Component
        className="label-heading"
        htmlFor={htmlFor}
        id={id}
      >
        {children}
        {required && (
          <span
            aria-hidden="true"
            className="required label-required"
          />
        )}
      </Component>
      {subLabel && (
        <p
          className="label-sublabel"
          id={subLabelId}
        >
          {subLabel}
        </p>
      )}
    </div>
  </div>
)

export default LabelHTMLExample
