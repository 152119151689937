import React, { ComponentProps, useState } from 'react'
import { FormText, Select } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
type Value = ComponentProps<typeof Select>['value']

const SELECT_OPTIONS = [
  { label: 'Helsinki', value: 'helsinki-1' },
  { label: 'Espoo', value: 'espoo-2' },
  { label: 'Tampere', value: 'tampere-3' },
  { label: 'Vantaa', value: 'vantaa-4' },
  { label: 'Oulu', value: 'oulu-5' },
  { label: 'Turku', value: 'turku-6' },
  { label: 'Jyväskylä', value: 'jyvaskyla-7' },
  { label: 'Lahti', value: 'lahti-8' },
  { label: 'Kuopio', value: 'kuopio-9' },
  { label: 'Pori', value: 'pori-10' }
]

const SelectExample = () => {
  const [option, setOption] = useState<Value>(null)

  return (
    <>
      <Select
        label="Kaupunki"
        onChange={value => {
          setOption(value)
        }}
        options={SELECT_OPTIONS}
        value={option}
      />
      <FormText>
        Valittu: {option ? <Code>{JSON.stringify(option)}</Code> : '-'}
      </FormText>
    </>
  )
}

const SelectVariations = () => {
  const [value1, setValue1] = useState<Value>(null)
  const [value2, setValue2] = useState<Value>(null)
  const [value3, setValue3] = useState<Value>(null)
  const [value4, setValue4] = useState<Value>(null)

  return (
    <>
      <Select
        label="Select"
        subLabel="possible to select just one option"
        options={SELECT_OPTIONS}
        value={value1}
        onChange={val => setValue1(val)}
      />
      {`selected value: ${JSON.stringify(value1)}`}
      <Select
        label="Select with search"
        subLabel="possible to select just one option"
        options={SELECT_OPTIONS}
        value={value2}
        searchable
        onChange={val => setValue2(val)}
      />
      {`selected value: ${JSON.stringify(value2)}`}
      <Select
        label="Select"
        subLabel="possible to select multiple options"
        options={SELECT_OPTIONS}
        value={undefined}
        onChange={val => setValue3(val)}
      />
      {`selected value: ${JSON.stringify(value3)}`}
      <Select
        label="Select with search"
        subLabel="possible to select multiple options"
        options={SELECT_OPTIONS}
        value={undefined}
        searchable
        onChange={val => setValue4(val)}
      />
      {`selected value: ${JSON.stringify(value4)}`}
      <Select
        label="Select"
        subLabel="Disabled"
        options={SELECT_OPTIONS}
        value={value1}
        disabled
      />
    </>
  )
}

export { SelectExample, SelectVariations }
