import React from 'react'

import { List, ListItem, Paragraph, Select } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Link } from '../../components/Link'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { SelectDisabledExample } from '../../examples/react/SelectDisabledExample'
import { SelectGroupSimpleExample } from '../../examples/react/SelectGroupSimpleExample'
import { SelectRequiredExample } from '../../examples/react/SelectRequiredExample'
import { SelectExample } from '../../examples/react/SelectExample'
import { SelectExample2 } from '../../examples/react/SelectExample2'
import { SelectExample3 } from '../../examples/react/SelectExample3'
import { SelectExample4 } from '../../examples/react/SelectExample4'
import { SelectDisabledOptionsExample } from '../../examples/react/SelectDisabledOptionsExample'
import { SelectGroupAdvancedExample } from '../../examples/react/SelectGroupAdvancedExample'
import { SelectSearchableClearExample } from '../../examples/react/SelectSearchableClearExample'
import { SelectSearchableErrorExample } from '../../examples/react/SelectSearchableErrorExample'
import { SelectSearchableExample } from '../../examples/react/SelectSearchableExample'
import { SelectSearchableHugeExample } from '../../examples/react/SelectSearchableHugeExample'
import { SelectSizeExample } from '../../examples/react/SelectSizeExample'
import IconHTMLExample from '../../examples/html/IconHTMLExample'
import LabelHTMLExample from '../../examples/html/LabelHTMLExample'
import SelectIsLoadingExample from '../../examples/react/SelectIsLoadingExample'
import SelectSearchableIsLoadingExample from '../../examples/react/SelectSearchableIsLoadingExample'
import SelectClearableSearchibleIsLoadingExample from '../../examples/react/SelectClearableSearchableIsLoadingExample'

const SELECT_HTML_OPTIONS = [
  { disabled: false, label: 'Valitse…', value: '' },
  { disabled: false, label: 'Espoo', value: 'espoo' },
  { disabled: false, label: 'Helsinki', value: 'helsinki' },
  { disabled: true, label: 'Turku', value: 'turku' }
] as const

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Select"
    components={[{ component: Select }]}
  >
    <Section title="Perustoiminnallisuus">
      <Paragraph>
        <Code>Select</Code>-komponentti soveltuu valintojen tekemiseen, kun
        vaihtoehtoja on useita (esim. enemmän kuin 4).
      </Paragraph>
      <Paragraph>
        Jos valintaa ei ole tehty, näytetään placeholder-teksti.
      </Paragraph>
      <Playground
        enableOverflow
        example={SelectExample}
        WrapperComponent={FormLayoutWrapper}
      />
      <Paragraph>
        Jokin vaihtoehdoista voidaan myös esivalita lomakkeelle saavuttaessa.
        Perusversio ei mahdollista valinnan poistamista (ellei valintalistassa
        ole tarjottu tyhjää valintaa).{' '}
        <a href="#haku-searchable">Hakuversiossa</a> voidaan käyttää{' '}
        <Code>clearable</Code>-proppia, joka mahdollistaa valinnan poistamisen.
      </Paragraph>
      <Playground
        enableOverflow
        example={SelectExample2}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Pakollisuus">
      <Playground
        enableOverflow
        example={SelectRequiredExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Haku (searchable)">
      <Playground
        enableOverflow
        example={SelectSearchableExample}
        WrapperComponent={FormLayoutWrapper}
      />
      <SectionHeading>
        Tyhjennys-painikkeella (searcable + clearable)
      </SectionHeading>
      <Paragraph>
        Tyhjennys-painike poistaa valinnan. Clear koskee valintaa, ei hakusanaa.
      </Paragraph>
      <Playground
        enableOverflow
        example={SelectSearchableClearExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Help ja sublabel">
      <Playground
        enableOverflow
        example={SelectExample3}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="onBlur, onChange ja focus">
      <Playground
        enableOverflow
        example={SelectExample4}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Pienempi koko">
      <Playground
        enableOverflow
        example={SelectSizeExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Virheviesti">
      <Playground
        enableOverflow
        example={SelectSearchableErrorExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Vaihtoehtojen ryhmitys">
      <SectionHeading>
        Ryhmän tyyppinä <Code>string</Code>
      </SectionHeading>
      <Playground
        enableOverflow
        example={SelectGroupSimpleExample}
        WrapperComponent={FormLayoutWrapper}
      />
      <SectionHeading>
        Ryhmän tyyppinä <Code>{'{ label: string, value: string }'}</Code>
      </SectionHeading>
      <Playground
        enableOverflow
        example={SelectGroupAdvancedExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Vaihtoehtojen iso määrä">
      <Playground
        enableOverflow
        example={SelectSearchableHugeExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Disabloidut optiot">
      <Playground
        enableOverflow
        example={SelectDisabledOptionsExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="isLoading">
      <Paragraph>
        Mikäli dataa täytyy ladata, komponentille voidaan asettaa{' '}
        <Code>isLoading</Code>-proppi. Tällöin komponentissa näkyy spinneri.
      </Paragraph>
      <Playground example={SelectIsLoadingExample} />
      <Paragraph>
        Haettavassa kentässä näytetään teksti &quot;Vaihtoehtoja ladataan&quot;,
        kun <Code>isLoading</Code> on käytössä. Tämä korvaa oletustekstin
        &quot;Vaihtoehtoja ei löytynyt&quot;.
      </Paragraph>
      <Playground example={SelectSearchableIsLoadingExample} />
      <Paragraph>
        Kentässä, jossa on tyhjennyspainike, latausikoni korvaa
        tyhjennyspainikkeen mikäli lataus on käynnissä
      </Paragraph>
      <Playground example={SelectClearableSearchibleIsLoadingExample} />
    </Section>
    <Section title="Disabloitu">
      <Paragraph>
        Ei-muokattava tieto tulisi yleensä esittää{' '}
        <Code>
          <Link page="Detail" />
        </Code>
        -komponentilla. Jos tieto on väliaikaisesti disabloitu (esim. lomakkeen
        muut valinnat vaikuttavat siihen, tarvitseeko tietoa syöttää), silloin
        voidaan käyttää disabloitua kenttää. Ruudunlukijalla disabled-kenttä
        hypätään yli.
      </Paragraph>
      <Playground
        enableOverflow
        example={SelectDisabledExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Verkkopalvelun komponentti">
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <>
          <LabelHTMLExample htmlFor="select-html-example-1">
            Kaupunki
          </LabelHTMLExample>
          <div className="custom-select-container">
            <select
              className="custom-select"
              id="select-html-example-1"
            >
              {SELECT_HTML_OPTIONS.map(option => (
                <option
                  disabled={option.disabled}
                  key={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
            <div className="custom-select-icon">
              <IconHTMLExample
                name="ChevronDownIcon"
                size="lg"
              />
            </div>
          </div>
        </>
      </Playground>
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <>
          <LabelHTMLExample htmlFor="select-html-danger-example">
            Kaupunki
          </LabelHTMLExample>
          <div className="custom-select-container custom-select-container-danger">
            <select
              className="custom-select"
              id="select-html-danger-example"
            >
              {SELECT_HTML_OPTIONS.map(option => (
                <option
                  disabled={option.disabled}
                  key={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
            <div className="custom-select-icon">
              <IconHTMLExample
                name="ChevronDownIcon"
                size="lg"
              />
            </div>
          </div>
        </>
      </Playground>
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <>
          <LabelHTMLExample
            disabled
            htmlFor="select-html-disabled-example"
          >
            Kaupunki
          </LabelHTMLExample>
          <div className="custom-select-container disabled">
            <select
              className="custom-select"
              id="select-html-disabled-example"
              disabled
            >
              {SELECT_HTML_OPTIONS.map(option => (
                <option
                  disabled={option.disabled}
                  key={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
            <div className="custom-select-icon">
              <IconHTMLExample
                name="ChevronDownIcon"
                size="lg"
              />
            </div>
          </div>
        </>
      </Playground>
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <>
          <LabelHTMLExample
            htmlFor="select-required-html-example"
            required
          >
            Kaupunki
          </LabelHTMLExample>
          <div className="custom-select-container">
            <select
              className="custom-select"
              aria-required
              id="select-required-html-example"
            >
              {SELECT_HTML_OPTIONS.map(option => (
                <option
                  disabled={option.disabled}
                  key={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
            <div className="custom-select-icon">
              <IconHTMLExample
                name="ChevronDownIcon"
                size="lg"
              />
            </div>
          </div>
        </>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Wave ilmoittaa virheellisesti saavutettavuusongelmasta, että{' '}
        <Code>Select</Code>in labelia ja inputia ei ole yhdistetty oikein
        (Orphaned form label). Tämä ei vaadi toimenpiteitä. Ilmoitus johtuu{' '}
        <Code>Select</Code>in teknisen toteutuksen monimutkaisuudesta. Kyseessä
        ei ole natiivi html select, vaan koostuu painikkeesta ja vaihtoehtojen
        listasta. Toteutus on saavutettavaja ruudunlukijalla kuunneltaessa tieto
        välittyy oikein. Ilmoitus ei esiinny searchable-versiolla.
      </Paragraph>
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>Placeholder-teksti ei saa korvata labelia.</ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
