import React, { ComponentProps, useState } from 'react'

import { FormGroup, FormText, Select } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

type Value = ComponentProps<typeof Select>['value']

const SELECT_OPTIONS = [...Array(10001).keys()].map(i => ({
  label: `Vaihtoehto ${i}${
    i % 25
      ? ''
      : ' with lorem ipsum dolor sit amet consectetuer adipiscing elit sed posuere interdum sem quisque ligula eros ullamcorper quis'
  }`,
  value: `option-${i}`,
  group: `Ryhmä ${Math.ceil(i / 50) * 50}`
}))

const SelectSearchableHugeExample = () => {
  const [option, setOption] = useState<Value>(null)

  return (
    <FormGroup noMargin>
      <Select
        label="Vaihtoehto"
        onChange={value => setOption(value)}
        options={SELECT_OPTIONS}
        searchable
        value={option}
      />
      <FormText>
        Valittu: {option ? <Code>{JSON.stringify(option)}</Code> : '-'}
      </FormText>
    </FormGroup>
  )
}

export { SelectSearchableHugeExample }
