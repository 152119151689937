import React, { ComponentProps, useState } from 'react'
import { Select } from '@te-digi/styleguide'
type Value = ComponentProps<typeof Select>['value']

const SELECT_OPTIONS = [
  { label: 'Helsinki', value: 'helsinki-1' },
  { label: 'Espoo', value: 'espoo-2' },
  { label: 'Tampere', value: 'tampere-3' },
  { label: 'Vantaa', value: 'vantaa-4' },
  { label: 'Oulu', value: 'oulu-5' }
]

const SelectExample3 = () => {
  const [option, setOption] = useState<Value>(SELECT_OPTIONS[0])

  return (
    <Select
      help="Help"
      label="Kaupunki"
      subLabel="Sub Label"
      onChange={value => {
        setOption(value)
      }}
      options={SELECT_OPTIONS}
      value={option}
    />
  )
}

export { SelectExample3 }
