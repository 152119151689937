import React, { ComponentProps, useState } from 'react'
import { Select } from '@te-digi/styleguide'
type Value = ComponentProps<typeof Select>['value']

const SELECT_OPTIONS = [
  { label: 'Helsinki', value: 'helsinki-1' },
  { label: 'Espoo', value: 'espoo-2' },
  { label: 'Tampere', value: 'tampere-3', disabled: true },
  { label: 'Vantaa', value: 'vantaa-4' },
  { label: 'Oulu', value: 'oulu-5' },
  { label: 'Turku', value: 'turku-6' },
  { label: 'Jyväskylä', value: 'jyvaskyla-7', disabled: true },
  { label: 'Lahti', value: 'lahti-8' },
  { label: 'Kuopio', value: 'kuopio-9' },
  { label: 'Pori', value: 'pori-10', disabled: true }
]

const SelectDisabledOptionsExample = () => {
  const [option, setOption] = useState<Value>(null)

  return (
    <Select
      description={`Valittu: ${
        option ? `${option.label} (${option.value})` : '-'
      }`}
      label="Kaupunki"
      onChange={value => {
        setOption(value)
      }}
      options={SELECT_OPTIONS}
      value={option}
    />
  )
}

export { SelectDisabledOptionsExample }
