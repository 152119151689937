import React, { ComponentProps, useState } from 'react'
import { FormGroup, FormText, Select } from '@te-digi/styleguide'
type Value = ComponentProps<typeof Select>['value']

const SELECT_OPTIONS = [
  { label: 'Helsinki', value: 'helsinki-1' },
  { label: 'Espoo', value: 'espoo-2' },
  { label: 'Tampere', value: 'tampere-3' },
  { label: 'Vantaa', value: 'vantaa-4' },
  { label: 'Oulu', value: 'oulu-5' }
]

const SelectSearchableClearExample = () => {
  const [option, setOption] = useState<Value>(SELECT_OPTIONS[0])

  return (
    <FormGroup noMargin>
      <Select
        searchable
        clearable
        label="Kaupunki"
        onChange={value => setOption(value)}
        options={SELECT_OPTIONS}
        value={option}
      />
      <FormText>
        Valittu: {option ? `${option.label} (${option.value})` : '-'}
      </FormText>
    </FormGroup>
  )
}

export { SelectSearchableClearExample }
