import React, { ComponentProps, useState } from 'react'

import { Button, Select, Spacer } from '@te-digi/styleguide'
type Value = ComponentProps<typeof Select>['value']
const SELECT_OPTIONS = [
  { label: 'Helsinki', value: 'helsinki-1' },
  { label: 'Espoo', value: 'espoo-2' },
  { label: 'Tampere', value: 'tampere-3' },
  { label: 'Vantaa', value: 'vantaa-4' },
  { label: 'Oulu', value: 'oulu-5' }
]
const SelectClearableSearchibleIsLoadingExample = () => {
  const [value, setValue] = useState<Value>(SELECT_OPTIONS[0])
  const [clearableIsLoading, setClearableIsLoading] = useState(false)

  return (
    <>
      <Select
        label="Kaupunki"
        options={SELECT_OPTIONS}
        clearable
        searchable
        value={value}
        isLoading={clearableIsLoading}
        onChange={val => setValue(val)}
      />
      <Spacer marginBottom={'sm'} />
      <Button onClick={() => setClearableIsLoading(!clearableIsLoading)}>
        {`isLoading: ${clearableIsLoading} `}
      </Button>
    </>
  )
}

export default SelectClearableSearchibleIsLoadingExample
