import React, { ComponentProps, useState } from 'react'
import { FormGroup, FormText, Select } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
type Value = ComponentProps<typeof Select>['value']

const SELECT_OPTIONS = [
  { label: 'Helsinki', value: 'helsinki-1' },
  { label: 'Espoo', value: 'espoo-2' },
  { label: 'Tampere', value: 'tampere-3' },
  { label: 'Vantaa', value: 'vantaa-4' },
  { label: 'Oulu', value: 'oulu-5' },
  { label: 'Turku', value: 'turku-6' },
  { label: 'Jyväskylä', value: 'jyvaskyla-7' },
  { label: 'Lahti', value: 'lahti-8' },
  { label: 'Kuopio', value: 'kuopio-9' },
  { label: 'Pori', value: 'pori-10' }
]

const SelectSearchableExample = () => {
  const [option, setOption] = useState<Value>(SELECT_OPTIONS[0])
  return (
    <FormGroup>
      <Select
        searchable
        label="Kaupunki"
        onChange={value => {
          setOption(value)
        }}
        options={SELECT_OPTIONS}
        value={option}
      />
      <FormText>
        Valittu: {option ? <Code>{JSON.stringify(option)}</Code> : '-'}
      </FormText>
    </FormGroup>
  )
}

export { SelectSearchableExample }
