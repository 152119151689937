import React, { ComponentProps, useState } from 'react'

import { FormGroup, FormText, Select } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

type Value = ComponentProps<typeof Select>['value']

const SELECT_OPTIONS_WITH_GROUPS = [
  { label: 'Helsinki', value: 'helsinki', group: 'Kaupunki' },
  { label: 'Suomi', value: 'suomi', group: 'Maa' },
  { label: 'Espoo', value: 'espoo', group: 'Kaupunki' },
  {
    label: 'Ruotsi',
    value: 'ruotsi',
    group: 'Maa'
  },
  { label: 'Tampere', value: 'tampere', group: 'Kaupunki' },
  { label: 'Norja', value: 'norja', group: 'Maa' },
  { label: 'Seinäjoki', value: 'seinajoki', group: 'Kaupunki' },
  {
    label: 'Tanska',
    value: 'tanska',
    group: 'Maa'
  }
]

const SelectGroupSimpleExample = () => {
  const [option, setOption] = useState<Value>(SELECT_OPTIONS_WITH_GROUPS[0])

  return (
    <FormGroup noMargin>
      <Select
        filter="start"
        label="Kaupunki"
        onChange={value => setOption(value)}
        options={SELECT_OPTIONS_WITH_GROUPS}
        searchable
        value={option}
      />
      <FormText>
        Valittu: {option ? <Code>{JSON.stringify(option)}</Code> : '-'}
      </FormText>
    </FormGroup>
  )
}

export { SelectGroupSimpleExample }
