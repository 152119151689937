import React, { ComponentProps, useRef, useState } from 'react'
import { Button, FormGroup, Select } from '@te-digi/styleguide'
type Value = ComponentProps<typeof Select>['value']

const SELECT_OPTIONS = [
  { label: 'Helsinki', value: 'helsinki-1' },
  { label: 'Espoo', value: 'espoo-2' },
  { label: 'Tampere', value: 'tampere-3' },
  { label: 'Vantaa', value: 'vantaa-4' },
  { label: 'Oulu', value: 'oulu-5' }
]

const SelectExample4 = () => {
  const [option, setOption] = useState<Value>(null)
  const selectRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <FormGroup>
        <Select
          label="Kaupunki"
          onBlur={() => console.log('SelectExample: onBlur')}
          onChange={value => {
            setOption(value)
            console.log('SelectExample: onChange')
          }}
          options={SELECT_OPTIONS}
          ref={selectRef}
          value={option}
        />
      </FormGroup>
      <FormGroup noMargin>
        <Button onClick={() => selectRef.current?.focus()}>Focus</Button>
      </FormGroup>
    </>
  )
}

export { SelectExample4 }
