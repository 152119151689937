import React, { ComponentProps, useState } from 'react'

import { FormGroup, Select } from '@te-digi/styleguide'

type Value = ComponentProps<typeof Select>['value']

const SELECT_OPTIONS = [
  { label: 'Helsinki', value: 'helsinki-1' },
  { label: 'Espoo', value: 'espoo-2' },
  { label: 'Tampere', value: 'tampere-3' },
  { label: 'Vantaa', value: 'vantaa-4' },
  { label: 'Oulu', value: 'oulu-5' }
]

const SelectSearchableErrorExample = () => {
  const [option, setOption] = useState<Value>(null)

  return (
    <FormGroup noMargin>
      <Select
        label="Kaupunki"
        error="Virheviesti"
        onChange={value => setOption(value)}
        options={SELECT_OPTIONS}
        searchable
        value={option}
      />
    </FormGroup>
  )
}

export { SelectSearchableErrorExample }
